<template>
    <div class="register form-wrapper">
        <h1>Register for <a href="#">playgrounds.co</a></h1>
        <p class="center">Already have an account? <router-link :to="{ name: 'login' }">Login here</router-link></p>
        
        <Spacer :size="50" />

        <form @submit.prevent="register">
            <div class="input-group">
                <input 
                    type="text" 
                    name="full_name"
                    v-model="full_name"
                    id="full_name" 
                    placeholder="Elon Musk">
                <label for="email">Your full name</label>
            </div>
            <div class="input-group">
                <input 
                    type="email" 
                    name="email"
                    v-model="email"
                    id="email" 
                    inputmode="email"
                    placeholder="elon@musk.com">
                <label for="email">Your email</label>
            </div>
            <div class="input-group">
                <input 
                    type="text" 
                    v-model="username"
                    name="username" 
                    id="username" 
                    placeholder="elonmusk">
                <label for="email">Your username</label>
            </div>
            <div class="input-group">
                <input 
                    type="password" 
                    v-model="password"
                    name="password" 
                    id="password" 
                    placeholder="••••••••••••">
                <label for="email"> Your password</label>
                <span v-if="password.length != 0 && password.length < 8" class="note">Make sure the password at least 8 characters long and it includes a number and lowercase letter.</span>
            </div>

            <Spacer :size="20" />

            <button type="submit" class="btn primary full-width">Register <span v-if="username.length != 0" class="username">&nbsp;@{{ username }}</span></button>
            <span class="note">By creating an account, you agree to the <a href="#">Terms of Service</a>. To get more information on how we handle your privacy, see the <a href="#">Privacy Statement</a>.</span>

            <div class="notification-wrapper" v-if="message.text">
                <Spacer :size="20" />
                <InlineNotification 
                    :success="message.success">
                    {{ message.text }}
                </InlineNotification>
            </div>
        </form>
    </div>
</template>

<script>
import Spacer from "@/components/spacer";
import AuthService from "@/services/AuthService"
import InlineNotification from "@/components/notifications/inline-notification"

export default {
    name: 'register',
    data() {
        return {
            full_name: '',
            email: '',
            username: '',
            password: '',
            message: {
                text: '',
                success: false
            }
        }
    },
    methods: {
        async register() {
            try {
                const credentials = {
                    full_name: this.full_name,
                    email: this.email,
                    username: this.username,
                    password: this.password
                };

                const response = await AuthService.register(credentials);
                this.message = {
                    text: response.message,
                    success: response.success
                }

                var self = this;
                setTimeout(function() {
                    self.$router.push({ name: 'login' })
                }, 2000);
            } catch(error) {
                this.message = {
                    text: error.message,
                    success: false
                };
            }
        }
    },
    components: {
        Spacer,
        InlineNotification
    }
}
</script>

<style lang="scss" scoped>
button[type=submit] {
    .username {
        opacity: 0;
        transition: opacity 200ms ease;
    }

    &:hover {
        .username {
            opacity: 1;
        }
    }
}
</style>